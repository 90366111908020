<template>
  <div class="brand-detail">
    <img v-if="bannerImage" :src="bannerImage" class="brand-detail-banner" alt="banner image" />
    <div class="content-wrapper">
      <VueHeadline class="brand-headline" level="3" weightLevel="3">{{ category }}</VueHeadline>
      <div class="brand" v-for="brand in brands" :key="brand.id">
        <div
          class="logo"
          :style="{
            backgroundImage: `url(${brand.image}`,
          }"
        ></div>
        <div class="right-area">
          <VueHeadline level="3" weightLevel="6" color="grey-40" class="headline-text">{{
            brand.name
          }}</VueHeadline>
          <VueText
            sizeLevel="3"
            color="grey-30"
            class="text"
            :class="{ 'text-toggled': brand.isDescToggled }"
            v-html="brand.description"
          >
          </VueText>
          <div class="link-holder">
            <VueButton ghost @click="toggleDesc(brand)">
              <VueText sizeLevel="3" weightLevel="3" class="left-link"
                >Daha {{ brand.isDescToggled ? 'Az' : 'Fazla' }} Göster</VueText
              ></VueButton
            >
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrapper grey-bg" v-if="news.length">
      <VueHeadline class="brand-headline" level="3" weightLevel="3">Marka Haberleri</VueHeadline>
      <BrandList :brandItems="news" @nextpage="getContents(true)"></BrandList>
      <div class="show-more-wrapper">
        <div class="show-more" @click="showMore" v-if="getMoreNews">Daha fazla gör</div>
      </div>
    </div>
  </div>
</template>
<script>
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandList from '@/components/brand/BrandList/BrandList.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import StorageProps from '@/mixins/storageProps.js';
import { Content } from '@/services/Api/index';
import { maxBy } from '@/mixins/arrayUtils.js';
import { Pladis } from '@/services/Api/index.js';

export default {
  name: 'BrandPeyman',
  components: {
    VueText,
    VueHeadline,
    BrandList,
    VueButton,
  },
  mixins: [StorageProps],
  data() {
    return {
      bannerImage: null,
      brands: [],
      category: '',
      news: [],
      getMoreNews: true,
      isDescToggled: false,
      lastIndex: 0,
      lastId: 0,
      pageSize: 3,
    };
  },
  mounted() {
    this.getProducts(this.$route.params.id);
  },
  created() {
    this.getContents();
  },
  computed: {
    getBrandData() {
      let name,
        desc,
        img = '',
        detailImg = '';
      name = this.brandData.brand.name;
      desc = this.brandData.content;
      img = this.brandData.brand.image;
      detailImg = this.brandData.backgroundImage;

      return { name, desc, img, detailImg };
    },
  },
  methods: {
    getProducts(id) {
      Pladis.getProductsByCategoryId(id).then(response => {
        if (response.data && response.data.Data) {
          const { products, category, bannerImage } = response.data.Data;
          this.brands = products.map(i => {
            i.isDescToggled = false;
            return i;
          });
          this.category = category;
          this.bannerImage = bannerImage;
          this.$route.meta.appbarConfig.title = category;
          let product_names = [];
          let product_ids = [];
          let product_brands = [];
          products.forEach((value, index) => {
            product_brands[index] = 'PEYMAN';
            product_names[index] = value.name;
            product_ids[index] = value.order;
          });
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').category_id_hierarchy = '2 > ' + id;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').category_name_hierarchy =
            'Peyman > ' + category;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').category_path = window.location.pathname;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').order_currency = 'TRY';
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').product_brands = product_brands;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').product_names = product_names;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').product_ids = product_ids;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').trade_category = 'PEYMAN';
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').trade_sub_category = category;
        }
      });
    },
    toggleDesc(brand) {
      this.brands.map(i => {
        if (i.name === brand.name) {
          i.isDescToggled = !i.isDescToggled;
        }
      });
    },
    showMore() {
      this.lastIndex = this.news.length;
      this.lastId = maxBy(this.news, 'feedId');
      this.pageSize = 10;
      this.getContents(this.getMoreNews);
    },
    getContents(isNextPage) {
      Content.getPladisBrandNews(this.lastId, this.lastIndex, this.pageSize, 236).then(res => {
        const {
          Data: { contents },
        } = res.data;

        if (!contents || contents.length < this.pageSize) {
          this.getMoreNews = false;
        }

        if (isNextPage) {
          this.news.push(...contents);
        } else {
          this.news = contents;
        }
        if (this.news.length) {
          this.lastIndex = this.news.length;
          this.lastId = this.news[this.lastIndex - 1].feedId;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.brand {
  display: flex;
  margin-bottom: 15px;
  .logo {
    background-color: palette-color-level('white', '100');
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 70px;
    height: 70px;
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('1');
  }

  .right-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 15px;

    .headline-text {
      margin-bottom: palette-space-level(5);
    }
    /* .text {
      position: relative;
      line-height: 1.27;
    } */
    .text {
      position: relative;
      line-height: 1.27;
      padding-right: palette-space-level(10);
      max-height: 30px;
      overflow: hidden;
      max-width: 100%;

      &:after {
        content: '...';
        position: absolute;
        background: #fff;
        right: 11px;
        bottom: 0;
      }
      &-toggled {
        max-height: unset;

        &:after {
          display: none;
        }
      }
    }

    .link-holder {
      display: flex;
      flex-direction: row;
      align-items: space-between;

      .left-link {
        padding-right: palette-space-level(30);
      }
      .left-link,
      .right-link {
        padding-top: palette-space-level(10);
        line-height: 1.27;
        text-decoration: underline;
      }
    }
  }
}
.grey-bg {
  background-color: palette-color-level('grey', '10');
  text-align: center;
}
.content-wrapper {
  padding: palette-space-level('20');
}
.products-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.products-headline {
  padding: palette-space-level('10') 0;
}
.brand-headline {
  padding: palette-space-level('10') 0;
  text-align: left;
}
.brand-detail-banner {
  display: block;
  max-width: 100%;
  margin: auto;
}
.show-more-wrapper {
  display: inline-block;
  width: 100%;
  .show-more {
    width: 100%;
    max-width: 140px;
    height: 45px;
    line-height: 45px;
    border-radius: palette-radius-level('15');
    border: solid 2px palette-color-level('grey', '20');
    background-color: palette-color-level('white', '100');
    text-align: center;
    font-size: palette-font-size-level('4');
    font-weight: palette-font-weight-level('3');
    color: palette-color-level('grey', '40');
    margin: 0 auto;
  }
}
</style>
